import React from 'react'
import PropTypes from 'prop-types'
import { ProductDescription, ProductTitle } from './styled'

const Product = props => {
  const { id, description, imgUrl } = props.data
  return (
    <>
      <img src={imgUrl} alt={description} width={150} />
      <ProductTitle>{description}</ProductTitle>
      <ProductDescription>{`Ref: ${id}`}</ProductDescription>
    </>
  )
}
Product.propTypes = {
  data: PropTypes.object
}
export default Product
