import styled from 'styled-components'
import { Result } from 'antd'
import { colors } from 'src/common/constants'

export const ProductContainer = styled.div`
  background-color: ${colors.white};
  min-height: 200px;
  padding: 24px;
`
export const Feedback = styled(Result)`
  & .ant-result-title {
    color: ${colors.grey4};
  }
  & .ant-result-subtitle {
    font-size: 18px;
    color: ${colors.grey4};
  }
`