import styled from 'styled-components'
import { colors } from 'src/common/constants'

export const ProductTitle = styled.h3`
  font-weight: bold;
  color: ${colors.grey4};
  margin: 8px 0;
`
export const ProductDescription = styled.span`
  color: ${colors.grey4};
`
