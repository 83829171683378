import styled from 'styled-components'
import { Layout as AntLayout } from 'antd'

import { colors } from './constants'

export const Layout = styled(AntLayout)`
  /* background: ${colors.white}; */
`
export const Content = styled(AntLayout.Content)`
  /* padding: 16px; */
  min-height: calc(100vh - 155px);
`