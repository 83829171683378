import {
  GET_SCAN_DETAILS,
  GET_STORES,
  RESET_SCAN_DETAILS
} from '../action-types'

const initialState = { stores: [] }
const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_STORES:
      return { ...state, stores: payload }
    case GET_SCAN_DETAILS:
      return { ...state, product: payload }
    case RESET_SCAN_DETAILS:
      return { ...initialState, stores: state.stores }
    default:
      return state
  }
}
export default appReducer
