import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Input, Modal } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'

import {
  getScanDetails,
  resetScanDetails
} from 'src/redux-store/actions/app-actions'

import Loader from 'src/components/Loader'
import BarcodeScanner from 'src/components/BarcodeScanner'
import Product from 'src/components/Product'
import GridRotture from 'src/components/GridRotture'
import Footer from 'src/components/Footer'
import { Feedback, ProductContainer } from './styled'
import { Content } from 'src/common/styled'
import barcodeImg from '../../assets/img/barcode.png'

const Home = props => {
  const { intl, product, getScanDetailsAction, resetScanDetails } = props
  const [isLoading, setIsLoading] = useState(false)
  const [isScanning, setIsScanning] = useState(false)
  const [isScanDisabled, setIsScanDisabled] = useState(false)

  const getScanDetails = async code => {
    stopScanning()
    setIsLoading(true)

    await getScanDetailsAction(code)
    setIsLoading(false)
  }

  const openManualInsertModal = () => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'manualInsertModal.title' }),
      content: (
        <Input
          size={'large'}
          id={'code'}
          placeholder={intl.formatMessage({
            id: 'manualInsertModal.placeholder'
          })}
        />
      ),
      okText: <CheckOutlined />,
      cancelText: <CloseOutlined />,
      okButtonProps: { size: 'large' },
      cancelButtonProps: { size: 'large' },
      onOk: () => getScanDetails(document.getElementById('code').value)
    })
  }

  const startScanning = () => setIsScanning(true)
  const stopScanning = () => setIsScanning(false)

  const resetData = () => {
    setIsScanning(false)
    setIsScanDisabled(false)
    resetScanDetails()
  }

  const renderProduct = () => {
    let content = (
      <img
        style={{ display: 'block', margin: '0 auto', width: 200 }}
        src={barcodeImg}
        alt={'barcode'}
      />
    )

    if (isLoading) {
      content = <Loader height={'150px'} />
    }

    if (product) {
      content = <Product data={product} />
    }

    return <ProductContainer>{content}</ProductContainer>
  }

  const renderRotture = () => {
    if (isScanning) {
      return <BarcodeScanner onScan={getScanDetails} />
    }

    if (product) {
      return <GridRotture product={product} />
    }

    return (
      <Feedback
        icon={<div></div>}
        subTitle={<FormattedMessage id={'home.text.scanProductBarcode'} />}
      />
    )
  }

  return (
    <>
      <Content>
        {renderProduct()}
        {renderRotture()}
      </Content>
      <Footer
        isScanDisabled={isScanDisabled}
        onManualInsertClick={openManualInsertModal}
        onScanButtonClick={startScanning}
        onFinishButtonClick={resetData}
      />
    </>
  )
}

Home.propTypes = {
  getScanDetails: PropTypes.func,
  product: PropTypes.object,
  resetScanDetails: PropTypes.func
}

const mapStateToProps = state => ({
  product: state.app.product
})
const mapDispatchToProps = {
  getScanDetailsAction: getScanDetails,
  resetScanDetails
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home))
