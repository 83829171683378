import styled from 'styled-components'
import { Button, Layout } from 'antd'
import { colors } from '../../common/constants'

export const StyledFooter = styled(Layout.Footer)`
  padding: 0;
  color: ${colors.grey3};
  background: ${colors.white};
  position: relative;
  display: grid;
  gap: 72px;
  grid-template-columns: 1fr 1fr;
`

export const ScanButton = styled(Button)`
  position: absolute;
  width: 72px !important;
  height: 72px !important;
  top: -36px;
  left: calc(50% - 36px);
  z-index: 99;
  & > span[role='img'] {
    font-size: 200%;
  }
`

export const FooterButton = styled(Button)`
  width: 100%;
  height: min-content;
  color: ${colors.grey4};
  text-transform: uppercase;
  & > span[role='img'] {
    display: block;
    font-size: 200%;
  }
`
