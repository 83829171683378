import {
  GET_SCAN_DETAILS,
  GET_STORES,
  RESET_SCAN_DETAILS
} from '../action-types'
import api from 'src/common/api'
import { handleServerError } from 'src/common/utils'

export const getStores = () => async dispatch => {
  try {
    const res = await api.store.list()
    return dispatch({ type: GET_STORES, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getScanDetails = code => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.scan.details(code, storeId)
    return dispatch({ type: GET_SCAN_DETAILS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const resetScanDetails = () => {
  return { type: RESET_SCAN_DETAILS }
}
