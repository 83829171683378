import { injectIntl } from 'react-intl'
import { Col, List, Row } from 'antd'
import Card from 'src/components/Card'

const GridRotture = props => {
  const { intl, product } = props
  const {
    causaleRottura,
    ordersInProgress,
    projectedOrdersW0,
    projectedOrdersW1,
    projectedOrdersW2,
    projectedOrdersW3
  } = product
  return (
    <Row gutter={[16, 16]} style={{ padding: 16 }}>
      <Col span={12}>
        <Card title={intl.formatMessage({ id: 'card.causaleRottura.title' })}>
          {causaleRottura || '—'}
        </Card>
      </Col>
      <Col span={12}>
        <Card title={intl.formatMessage({ id: 'card.ordersInProgress.title' })}>
          {ordersInProgress || 0}
        </Card>
      </Col>
      <Col span={24}>
        <Card title={intl.formatMessage({ id: 'card.projectedOrders.title' })}>
          <List size={'small'} style={{ textAlign: 'left' }}>
            <List.Item>
              <List.Item.Meta
                title={intl.formatMessage({
                  id: 'card.projectedOrders.text0'
                })}
              />
              {+projectedOrdersW0 || 0}
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={intl.formatMessage({
                  id: 'card.projectedOrders.text1'
                })}
              />
              {+projectedOrdersW1 || 0}
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={intl.formatMessage({
                  id: 'card.projectedOrders.text2'
                })}
              />
              {+projectedOrdersW2 || 0}
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={intl.formatMessage({
                  id: 'card.projectedOrders.text3'
                })}
              />
              {+projectedOrdersW3 || 0}
            </List.Item>
          </List>
        </Card>
      </Col>
    </Row>
  )
}
export default injectIntl(GridRotture)
